// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.information {
  /* background: rgba(var(--bs-primary-rgb), 0.1); */
  /* padding: 1rem; */
  /* margin-bottom: 3rem; */
}
.information .title {
  color: var(--bs-secondary);
  background-color: rgba(var(--bs-secondary-rgb), 0.1);
  padding: 0.75rem;
  border-radius: 10px;
}
.information .subtitle {
  color: var(--bs-primary);
  padding: 0.5rem;
}
.information .anchor-section {
  position: relative;
  z-index: -1;
  padding-top: 10rem;
  margin-top: -10rem;
}
.information h2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@media (max-width: 1200px) {
  .information .anchor-section {
    padding-top: 10rem;
    margin-top: -10rem;
  }
}

@media (max-width: 992px) {
  .information .anchor-section {
    padding-top: 10rem;
    margin-top: -10rem;
  }
}

@media (max-width: 767px) {
  .information .anchor-section {
    padding-top: 25rem;
    margin-top: -25rem;
  }
}

@media (max-width: 576px) {
  .information .anchor-section {
    padding-top: 24.5rem;
    margin-top: -24.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Information/style.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,0BAA0B;EAC1B,oDAAoD;EACpD,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,wBAAwB;EACxB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,oBAAoB;IACpB,oBAAoB;EACtB;AACF","sourcesContent":[".information {\n  /* background: rgba(var(--bs-primary-rgb), 0.1); */\n  /* padding: 1rem; */\n  /* margin-bottom: 3rem; */\n}\n.information .title {\n  color: var(--bs-secondary);\n  background-color: rgba(var(--bs-secondary-rgb), 0.1);\n  padding: 0.75rem;\n  border-radius: 10px;\n}\n.information .subtitle {\n  color: var(--bs-primary);\n  padding: 0.5rem;\n}\n.information .anchor-section {\n  position: relative;\n  z-index: -1;\n  padding-top: 10rem;\n  margin-top: -10rem;\n}\n.information h2 {\n  margin-bottom: 1rem;\n  margin-top: 1rem;\n}\n\n@media (max-width: 1200px) {\n  .information .anchor-section {\n    padding-top: 10rem;\n    margin-top: -10rem;\n  }\n}\n\n@media (max-width: 992px) {\n  .information .anchor-section {\n    padding-top: 10rem;\n    margin-top: -10rem;\n  }\n}\n\n@media (max-width: 767px) {\n  .information .anchor-section {\n    padding-top: 25rem;\n    margin-top: -25rem;\n  }\n}\n\n@media (max-width: 576px) {\n  .information .anchor-section {\n    padding-top: 24.5rem;\n    margin-top: -24.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
