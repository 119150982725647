// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding-top: 4rem;
  max-width: 100% !important;
}

.footer .container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.footer .brand {
  display: flex;
  /* font-weight: 900; */
  color: var(--bs-primary);
  text-decoration: none;
  text-align: center;
  margin: 0;
}
.footer .social-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.75rem 1rem;
}
.footer .social {
  padding: 0 0.75rem;
  color: var(--bs-primary);
}
.footer .text {
  text-align: left;
  font-size: calc(10px + 0.5vmin);
  color: #707070;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,qBAAqB;EACrB,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,0BAA0B;AAC5B;AACA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B;AACA;EACE,gBAAgB;EAChB,+BAA+B;EAC/B,cAAc;EACd,SAAS;AACX","sourcesContent":[".footer {\n  padding-top: 4rem;\n  max-width: 100% !important;\n}\n\n.footer .container {\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n}\n.footer .brand {\n  display: flex;\n  /* font-weight: 900; */\n  color: var(--bs-primary);\n  text-decoration: none;\n  text-align: center;\n  margin: 0;\n}\n.footer .social-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0rem 0.75rem 1rem;\n}\n.footer .social {\n  padding: 0 0.75rem;\n  color: var(--bs-primary);\n}\n.footer .text {\n  text-align: left;\n  font-size: calc(10px + 0.5vmin);\n  color: #707070;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
