// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* style.css */
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: auto;
  max-height: 700px;
}

.carousel-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity for dimming effect */
  z-index: 1; /* Ensure the overlay is on top of the image */
}

.carousel-caption {
  position: relative;
  z-index: 2; /* Ensure the caption is above the overlay */
}
`, "",{"version":3,"sources":["webpack://./src/components/Carousel/style.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ;;;;;GAKC,EAAE,0CAA0C;EAC7C,UAAU,EAAE,8CAA8C;AAC5D;;AAEA;EACE,kBAAkB;EAClB,UAAU,EAAE,4CAA4C;AAC1D","sourcesContent":["/* style.css */\n.carousel-container {\n  position: relative;\n  width: 100%;\n  overflow: hidden;\n  height: auto;\n  max-height: 700px;\n}\n\n.carousel-img {\n  width: 100%;\n  height: auto;\n  object-fit: cover;\n}\n\n.carousel-overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(\n    0,\n    0,\n    0,\n    0.5\n  ); /* Adjust the opacity for dimming effect */\n  z-index: 1; /* Ensure the overlay is on top of the image */\n}\n\n.carousel-caption {\n  position: relative;\n  z-index: 2; /* Ensure the caption is above the overlay */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
