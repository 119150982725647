// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services {
  background: white;
  margin-bottom: 3rem;
}

.services .title {
  color: var(--bs-secondary);
  background-color: rgba(var(--bs-secondary-rgb), 0.1);
  margin-bottom: 2.5rem;
  padding: 0.75rem;
  border-radius: 10px;
}
.services .item {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  color: var(--bs-gray-700);
}

.services .item .icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--bs-primary);
}
.services .item .desc {
  text-align: left;
}
.services .anchor-section {
  position: relative;
  z-index: -1;
  padding-top: 10rem;
  margin-top: -10rem;
}

@media (max-width: 1200px) {
  .services .anchor-section {
    padding-top: 10rem;
    margin-top: -10rem;
  }
}

@media (max-width: 992px) {
  .services .anchor-section {
    padding-top: 10rem;
    margin-top: -10rem;
  }
}

@media (max-width: 767px) {
  .services .anchor-section {
    padding-top: 25rem;
    margin-top: -25rem;
  }
}

@media (max-width: 576px) {
  .services .anchor-section {
    padding-top: 25rem;
    margin-top: -25rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ServiceDesc/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,oDAAoD;EACpD,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,wBAAwB;AAC1B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF","sourcesContent":[".services {\n  background: white;\n  margin-bottom: 3rem;\n}\n\n.services .title {\n  color: var(--bs-secondary);\n  background-color: rgba(var(--bs-secondary-rgb), 0.1);\n  margin-bottom: 2.5rem;\n  padding: 0.75rem;\n  border-radius: 10px;\n}\n.services .item {\n  display: flex;\n  align-items: center;\n  margin-bottom: 2rem;\n  color: var(--bs-gray-700);\n}\n\n.services .item .icon {\n  font-size: 2rem;\n  margin-right: 1rem;\n  color: var(--bs-primary);\n}\n.services .item .desc {\n  text-align: left;\n}\n.services .anchor-section {\n  position: relative;\n  z-index: -1;\n  padding-top: 10rem;\n  margin-top: -10rem;\n}\n\n@media (max-width: 1200px) {\n  .services .anchor-section {\n    padding-top: 10rem;\n    margin-top: -10rem;\n  }\n}\n\n@media (max-width: 992px) {\n  .services .anchor-section {\n    padding-top: 10rem;\n    margin-top: -10rem;\n  }\n}\n\n@media (max-width: 767px) {\n  .services .anchor-section {\n    padding-top: 25rem;\n    margin-top: -25rem;\n  }\n}\n\n@media (max-width: 576px) {\n  .services .anchor-section {\n    padding-top: 25rem;\n    margin-top: -25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
