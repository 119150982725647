// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  max-width: 600px;
  margin: auto;
  padding: 1rem;
  border: 1px solid rgba(var(--bs-primary-rgb), 0.5);
  border-radius: 10px;
  /* background-color: rgb(var(--bs-secondary-rgb), 0.05); */
}

.form-label {
  font-weight: bold;
}

button {
  width: 100%;
}

.form-control {
  margin-bottom: 1rem;
}

.custom-checkbox .form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.custom-checkbox .form-check-input:focus {
  border-color: var(--bs-primary);
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary-rgb), 0.25);
}

/* Custom styles for form inputs */
input.form-control,
.form-control {
  /* color: var(--bs-primary); */
  border-color: var(--bs-primary);
  /* background-color: transparent; */
}

input.form-control:focus,
.form-control:focus {
  border-color: var(--bs-primary);
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary-rgb), 0.25);
}
`, "",{"version":3,"sources":["webpack://./src/components/Form/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,kDAAkD;EAClD,mBAAmB;EACnB,0DAA0D;AAC5D;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;EAC/B,0DAA0D;AAC5D;;AAEA,kCAAkC;AAClC;;EAEE,8BAA8B;EAC9B,+BAA+B;EAC/B,mCAAmC;AACrC;;AAEA;;EAEE,+BAA+B;EAC/B,0DAA0D;AAC5D","sourcesContent":["form {\n  max-width: 600px;\n  margin: auto;\n  padding: 1rem;\n  border: 1px solid rgba(var(--bs-primary-rgb), 0.5);\n  border-radius: 10px;\n  /* background-color: rgb(var(--bs-secondary-rgb), 0.05); */\n}\n\n.form-label {\n  font-weight: bold;\n}\n\nbutton {\n  width: 100%;\n}\n\n.form-control {\n  margin-bottom: 1rem;\n}\n\n.custom-checkbox .form-check-input:checked {\n  background-color: var(--bs-primary);\n  border-color: var(--bs-primary);\n}\n\n.custom-checkbox .form-check-input:focus {\n  border-color: var(--bs-primary);\n  box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary-rgb), 0.25);\n}\n\n/* Custom styles for form inputs */\ninput.form-control,\n.form-control {\n  /* color: var(--bs-primary); */\n  border-color: var(--bs-primary);\n  /* background-color: transparent; */\n}\n\ninput.form-control:focus,\n.form-control:focus {\n  border-color: var(--bs-primary);\n  box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary-rgb), 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
