// * Data

const contact = {
  address: {
    street: "325 S. Old Woodward Suite 200",
    city: "Birmingham",
    state: "MI",
    zipcode: "48009",
  },
  phone: "(248)238-8262",
  email: "admin@synergylit.com",
};

const heading = "Professional Court Reporting Services";

const highlights = [
  {
    desc: " Offering a complete array of electronic and stenographic court reporting services",
    icon: "bi-laptop",
  },
  {
    desc: "Our experienced staff is trained to handle any court reporting challenge",
    icon: "bi-lightbulb",
  },
  {
    desc: "Flexible deposition scheduling to fit your needs",
    icon: "bi-calendar-check",
  },
];

const services = [
  {
    id: "1",
    title: "Stenographic Court Reporting",
    description: `At Synergy Litigation Services, our stenographic court reporters utilize cutting-edge steno machines to capture every detail of your legal proceedings with unparalleled accuracy. These sophisticated devices, enhanced by advanced CAT (computer-aided transcription) software, allow us to work seamlessly with your team, delivering precise transcripts efficiently and effectively.`,
  },
  {
    id: "2",
    title: "Expert Realtime Transcription",
    description: `Experience the power of synergy with our realtime court reporting services. Instantly read, search, and annotate testimonies during your case. Our experienced reporters integrate smoothly into your workflow, providing immediate (realtime) transcripts during depositions and comprehensive copies upon completion. Available for both in-person and remote settings, our services keep you fully informed and engaged.`,
  },

  {
    id: "3",
    title: "Efficient Deposition Coordination",
    description: `At Synergy Litigation Services, we simplify your deposition process by integrating advanced technology with expert coordination. Our team works collaboratively with you to manage all logistics, reducing stress and enhancing the efficiency of your case preparation and management, ensuring everything runs smoothly.`,
  },
  {
    id: "4",
    title: "Secure Audio and Video Transcription",
    description: `Achieve flawless documentation with our secure audio and video transcription services. By leveraging the synergy between accuracy and advanced technology, we deliver high-quality transcripts that meet regulatory and professional standards, safeguarding your practice and ensuring reliable documentation.`,
  },
  {
    id: "5",
    title: "Professional Language Translation",
    description: `Our language translation services offer precise translations of legal documents, ensuring clarity and accuracy for international and multilingual cases. Trust our certified experts to work synergistically with your team, delivering translations that meet the highest legal standards and support your global legal needs.`,
  },
  {
    id: "6",
    title: "Professional Videography Services",
    description: `Capture every detail of your depositions with Synergy’s professional videography services. Our experienced videographers seamlessly integrate with your team, using the latest technology to provide clear, high-quality video recordings, ensuring you have a complete and reliable record for future reference.`,
  },
];

export { heading, services, highlights, contact };
