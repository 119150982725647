// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for the accordion */

.accordion {
  /* --bs-accordion-btn-color: var(--bs-primary); */
}
.accordion-body {
  /* color: var(--bs-gray-700);  */
}

.accordion-header .accordion-button {
  color: var(--bs-primary);
  /* background-color: rgba(var(--bs-secondary-rgb), 0.05); */
  /* background-color: rgba(0, 0, 0, 0.8); */
}

.accordion-header .accordion-button:not(.collapsed) {
  color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.2);
}

.accordion-header .accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.4); /* Secondary color focus shadow */
}

/* Custom styles for the accordion icons */
.accordion-button::after {
  /* Set the color of the icon */
  color: white; /* Or any other color you want, e.g., red, blue, etc. */

  /* Ensuring the icons are visible */
  filter: brightness(0) invert(1); /* This line will invert the black color to white */
}

/* Styling for when the accordion item is expanded */
.accordion-button:not(.collapsed)::after {
  /* You might want to change the color when the accordion is not collapsed (i.e., it is open) */
  color: white; /* Maintain consistent color or change if desired */
  filter: brightness(0) invert(1); /* Adjust or maintain for visibility */
}
`, "",{"version":3,"sources":["webpack://./src/components/Accordion/style.css"],"names":[],"mappings":"AAAA,oCAAoC;;AAEpC;EACE,iDAAiD;AACnD;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,wBAAwB;EACxB,2DAA2D;EAC3D,0CAA0C;AAC5C;;AAEA;EACE,wBAAwB;EACxB,kDAAkD;AACpD;;AAEA;EACE,0DAA0D,EAAE,iCAAiC;AAC/F;;AAEA,0CAA0C;AAC1C;EACE,8BAA8B;EAC9B,YAAY,EAAE,uDAAuD;;EAErE,mCAAmC;EACnC,+BAA+B,EAAE,mDAAmD;AACtF;;AAEA,oDAAoD;AACpD;EACE,8FAA8F;EAC9F,YAAY,EAAE,mDAAmD;EACjE,+BAA+B,EAAE,sCAAsC;AACzE","sourcesContent":["/* Custom styles for the accordion */\n\n.accordion {\n  /* --bs-accordion-btn-color: var(--bs-primary); */\n}\n.accordion-body {\n  /* color: var(--bs-gray-700);  */\n}\n\n.accordion-header .accordion-button {\n  color: var(--bs-primary);\n  /* background-color: rgba(var(--bs-secondary-rgb), 0.05); */\n  /* background-color: rgba(0, 0, 0, 0.8); */\n}\n\n.accordion-header .accordion-button:not(.collapsed) {\n  color: var(--bs-primary);\n  background-color: rgba(var(--bs-primary-rgb), 0.2);\n}\n\n.accordion-header .accordion-button:focus {\n  box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.4); /* Secondary color focus shadow */\n}\n\n/* Custom styles for the accordion icons */\n.accordion-button::after {\n  /* Set the color of the icon */\n  color: white; /* Or any other color you want, e.g., red, blue, etc. */\n\n  /* Ensuring the icons are visible */\n  filter: brightness(0) invert(1); /* This line will invert the black color to white */\n}\n\n/* Styling for when the accordion item is expanded */\n.accordion-button:not(.collapsed)::after {\n  /* You might want to change the color when the accordion is not collapsed (i.e., it is open) */\n  color: white; /* Maintain consistent color or change if desired */\n  filter: brightness(0) invert(1); /* Adjust or maintain for visibility */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
