// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App h1,
h2,
a {
  text-align: center;
}

.safe-area {
  display: flex;
  /* padding: 0.75rem 0.75rem 0.75rem 0.75rem; */
  flex-direction: column;
}

/* ! Development ONLY to test Grids */
/* body {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

h3 {
  margin-top: 2rem;
}

.row {
  margin-bottom: 1rem;
}
.row .row {
  margin-top: 1rem;
  margin-bottom: 0;
}
[class*="col-"] {
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;EAGE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8CAA8C;EAC9C,sBAAsB;AACxB;;AAEA,qCAAqC;AACrC;;;;;;;;;;;;;;;;;;;;;;;;;;;GA2BG","sourcesContent":[".App h1,\nh2,\na {\n  text-align: center;\n}\n\n.safe-area {\n  display: flex;\n  /* padding: 0.75rem 0.75rem 0.75rem 0.75rem; */\n  flex-direction: column;\n}\n\n/* ! Development ONLY to test Grids */\n/* body {\n  padding-top: 2rem;\n  padding-bottom: 2rem;\n}\n\nh3 {\n  margin-top: 2rem;\n}\n\n.row {\n  margin-bottom: 1rem;\n}\n.row .row {\n  margin-top: 1rem;\n  margin-bottom: 0;\n}\n[class*=\"col-\"] {\n  align-items: center;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  background-color: rgba(86, 61, 124, 0.15);\n  border: 1px solid rgba(86, 61, 124, 0.2);\n}\n\nhr {\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
